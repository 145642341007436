import { FC, ReactElement, ReactNode } from 'react';
import { DataJobSource, useItemSelection, useItemSelectionElements } from './SelectItemsContext';
import { ClientModule } from '../../models/ClientModule';
import { DataJobSourceType } from '../../models/DataImport';
import { ExportModes } from '../../components/shared/data-export/DataExportModal';
import LanguageUtils from '../../utils/LanguageUtils';

export type DefaultSelectionBarProps = {
  selectionText?: ReactElement | ReactNode;
  clientModule?: ClientModule | null;
  source?: DataJobSource;
  forceMode?: ExportModes;
};

const DefaultSelectionBar: FC<DefaultSelectionBarProps> = () => {
  const { DataExportButton, props } = useItemSelectionElements();
  const { selectionText, clientModule, source, forceMode } = props;
  const { mutateSelection } = useItemSelection();

  return (
    <div className="flex items-center gap-3">
      <span
        onClick={() => {
          mutateSelection.setSource(
            source ||
              (clientModule
                ? { id: clientModule.id, name: LanguageUtils.getTranslation('name', clientModule.translations), type: DataJobSourceType.Module }
                : { type: DataJobSourceType.Activity }),
          );
          mutateSelection.setForceMode(forceMode);
        }}
      >
        <DataExportButton />
      </span>
      <div className="border-gray-5 h-[24px] border-l" /> {selectionText}
    </div>
  );
};

export default DefaultSelectionBar;
