import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Checkbox from '../form-control/Checkbox';
import { useTranslation } from 'react-i18next';
import { ExportModes } from './DataExportModal';
import Tooltip from '../Tooltip';
import { RiskStatus } from '../../../models/Risk';
import { nextTick } from '../../../utils/ReactUtils';
import { Option } from '../../Option';
import { DataExportOptions } from '../../../models/DataExport';
import InfoIcon from '../icon/InfoIcon';

type Props = {
  modalMode: ExportModes;
  exportCoverPage: boolean;
  setExportCoverPage: (value: boolean) => void;
  exportReferences: boolean;
  setExportReferences: (value: boolean) => void;
  exportAttachments: boolean;
  setExportAttachments: (value: boolean) => void;
  evidenceReport: boolean;
  setEvidenceReport: (value: boolean) => void;
  distributionLogs: boolean;
  setDistributionLogs: (value: boolean) => void;
  exportRisk: boolean;
  setExportRisk: (value: boolean) => void;
  dataExportOptions?: DataExportOptions;
  showRiskOwner: boolean;
  setShowRiskOwner: (value: boolean) => void;
  riskStatuses: Option<string | RiskStatus, boolean>[];
  setRiskStatuses: (
    value: Option<string | RiskStatus, boolean>[] | ((prev: Option<string | RiskStatus, boolean>[]) => Option<string | RiskStatus, boolean>[]),
  ) => void;
};

const DataExportSettings: FC<Props> = (props) => {
  const {
    modalMode,
    exportCoverPage,
    setExportCoverPage,
    exportReferences,
    setExportReferences,
    exportAttachments,
    setExportAttachments,
    evidenceReport,
    setEvidenceReport,
    distributionLogs,
    setDistributionLogs,
    exportRisk,
    setExportRisk,
    dataExportOptions,
    showRiskOwner,
    setShowRiskOwner,
    riskStatuses,
    setRiskStatuses,
  } = props;
  const { t } = useTranslation(['data-import-export', 'risk']);
  const [showRiskSettings, setShowRiskSettings] = useState(false);
  const containerEl = useRef<HTMLDivElement>(null);
  const toggleRiskSettings = useCallback(() => {
    setShowRiskSettings((prev) => !prev);
    nextTick(() => {
      const container = containerEl.current;
      if (container && container.parentElement) {
        container.parentElement.scrollTo({ top: container.parentElement.scrollHeight, behavior: 'smooth' });
      }
    });
  }, []);

  const toggleStatus = useCallback(
    (riskStatusOption: Option<string | RiskStatus, boolean>) => {
      setRiskStatuses((prev) => {
        const index = prev.findIndex((option) => option.id === riskStatusOption.id);
        if (index !== -1) {
          const updatedStatuses = [...prev];
          updatedStatuses[index] = { ...prev[index], value: !riskStatusOption.value };
          return updatedStatuses;
        }
        return prev;
      });
    },
    [setRiskStatuses],
  );

  useEffect(() => {
    if (riskStatuses.every((x) => !x.value)) {
      setExportRisk(false);
      setShowRiskSettings(false);
    }
  }, [riskStatuses, setExportRisk]);

  useEffect(() => {
    if (exportRisk) {
      setRiskStatuses((prev) => prev.map((option) => ({ ...option, value: true })));
    }
  }, [exportRisk, setRiskStatuses]);

  return (
    <div ref={containerEl} className="flex flex-col gap-1">
      {modalMode === 'pdf' && (
        <Checkbox
          value={exportCoverPage}
          onChange={setExportCoverPage}
          label={t('export.modal.include-cover-page')}
          description={t('export.modal.include-cover-page-description')}
        />
      )}

      {dataExportOptions?.hasReferences && (
        <Tooltip text={modalMode === 'excel' ? t('export.modal.references-disabled-reason') : null}>
          {(tooltip) => (
            <div {...tooltip}>
              <Checkbox
                value={modalMode === 'excel' || exportReferences}
                onChange={setExportReferences}
                label={
                  <span>
                    {t('export.modal.include-references')}{' '}
                    <Tooltip text={t('export.included-files-note')}>
                      {(tooltip) => (
                        <span {...tooltip}>
                          <InfoIcon className="h-5 w-5" />
                        </span>
                      )}
                    </Tooltip>
                  </span>
                }
                description={t('export.modal.include-references-description')}
                disabled={modalMode === 'excel'}
              />
            </div>
          )}
        </Tooltip>
      )}
      {dataExportOptions?.hasAttachments && (
        <Checkbox
          value={exportAttachments}
          onChange={setExportAttachments}
          label={
            <span>
              {t('export.modal.include-attachments')}{' '}
              <Tooltip text={t('export.included-files-note')}>
                {(tooltip) => (
                  <span {...tooltip}>
                    <InfoIcon className="h-5 w-5" />
                  </span>
                )}
              </Tooltip>
            </span>
          }
          description={t('export.modal.include-attachments-description')}
        />
      )}

      {modalMode === 'pdf' && (
        <>
          {dataExportOptions?.hasEvidenceReport && (
            <Checkbox
              value={evidenceReport}
              onChange={setEvidenceReport}
              label={t('export.modal.include-evidence-report')}
              description={t('export.modal.include-evidence-report-description')}
            />
          )}
          {dataExportOptions?.hasRisk && (
            <>
              <Checkbox
                value={exportRisk}
                onChange={setExportRisk}
                label={t('export.modal.include-risk')}
                description={t('export.modal.include-risk-description')}
              />
              {exportRisk && (
                <>
                  <a className="ml-8 cursor-pointer text-black underline" onClick={toggleRiskSettings}>
                    {!showRiskSettings ? t('export.modal.risk.settings.more') : t('export.modal.risk.settings.less')}
                  </a>
                  {showRiskSettings && (
                    <div className="ml-8">
                      <div className="my-2">
                        <label className="text-gray-2 block font-medium">{t('export.modal.risk.details.heading')}</label>
                        <div className="ml-2">
                          <Checkbox value={showRiskOwner} onChange={setShowRiskOwner} label={t('export.modal.risk.details.owner')} />
                        </div>
                      </div>
                      <div className="my-2">
                        <label className="text-gray-2 block font-medium">{t('export.modal.risk.filter.status.heading')}</label>
                        <div className="ml-2">
                          {riskStatuses.map((riskStatusOption) => (
                            <Checkbox
                              key={riskStatusOption.id}
                              value={riskStatusOption.value}
                              onChange={() => toggleStatus(riskStatusOption)}
                              label={t('export.modal.risk.filter.status.text', { status: riskStatusOption.text })}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {dataExportOptions?.hasDistributions && (
            <Checkbox
              value={distributionLogs}
              onChange={setDistributionLogs}
              label={t('export.modal.include-distribution-logs')}
              description={t('export.modal.include-distribution-logs-description')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DataExportSettings;
