import { FC, useCallback } from 'react';
import ClientFormService from '../../../services/ClientFormService';
import DataExportMetaSelection from './DataExportMetaSelection';
import { FormType } from '../../../models/FormTypes';
import StringUtils from '../../../utils/StringUtils';
import { DocumentListItem } from '../../../models/Document';
import { ApiResponse } from '../../../models/ApiResponse';
import { FormListItem } from '../../../models/Form';

type Props = {
  attachmentsSelectionChanged: (mainId: string, subId: string, include: boolean) => void;
  isLoading: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  loadMore: () => void;
  pagedData: ApiResponse<FormListItem[]> | null;
};

const DataExportChooseAttachments: FC<Props> = (props) => {
  const { attachmentsSelectionChanged, isLoading, searchValue, setSearchValue, loadMore, pagedData } = props;

  const loadAttachments = useCallback(
    (id: string) => {
      const cf = pagedData?.data.find((x) => x.id === id);
      return ClientFormService.getAttachments(id).then((res) => {
        return (
          res.data?.map((x, i) => {
            let title = x.fileName;
            if (cf?.type === FormType.Document) {
              const doc = cf as DocumentListItem;
              title = `${StringUtils.makePrefixWithNumber(
                doc.prefix,
                doc.documentNumber,
                doc.templateModuleTranslations,
              )}-${StringUtils.addLeadingZeros(i + 1)}-${title}`;
            }
            return {
              id: x.fileId,
              title,
            };
          }) ?? []
        );
      });
    },
    [pagedData?.data],
  );

  return (
    <DataExportMetaSelection
      subItemType="attachment"
      loadSubItems={loadAttachments}
      onItemStatusChange={attachmentsSelectionChanged}
      isLoading={isLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      loadMore={loadMore}
      pagedData={pagedData}
    />
  );
};

export default DataExportChooseAttachments;
