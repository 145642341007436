import { FC } from 'react';
import { Input } from '../form-control/Input';
import { useTranslation } from 'react-i18next';
import { ExportModes } from './DataExportModal';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';

type Props = {
  generatedFileName: string;
  fileNameInternal: string;
  setFileNameInternal: (value: string) => void;
  modes: readonly ExportModes[];
  modalMode: ExportModes;
  setModalMode: (value: ExportModes) => void;
};

const DataExportChooseDetails: FC<Props> = (props) => {
  const { generatedFileName, fileNameInternal, setFileNameInternal, modes, modalMode, setModalMode } = props;
  const { t } = useTranslation('data-import-export');

  return (
    <div className="flex flex-col gap-8">
      <Input
        label={t('export.modal.filename')}
        autoFocus
        placeholder={generatedFileName}
        value={fileNameInternal}
        onChange={(e) => setFileNameInternal(e.target.value)}
      />
      <div>
        <span className="text-color-3 text-dpm-12 mb-0">{t('export.modal.file-types-label')}</span>
        <div className="mb-4 flex gap-3">
          {modes.map((x) => (
            <div
              key={x}
              className={`border ${
                modalMode === x ? 'border-primary-1 shadow-lg' : 'border-transparent'
              } bg-background-1 text-primary-1 cursor-pointer select-none rounded-[5px] p-5`}
              {...mouseAndKeyboardCallbackProps(() => setModalMode(x))}
            >
              {t(`export.modal.file-types.${x}`)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataExportChooseDetails;
