import { FC, useCallback } from 'react';
import DataExportMetaSelection from './DataExportMetaSelection';
import ClientFormService from '../../../services/ClientFormService';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../models/FormTypes';
import StringUtils from '../../../utils/StringUtils';
import { FormReferenceDirection } from '../../../models/ClientForm';
import { ApiResponse } from '../../../models/ApiResponse';
import { FormListItem } from '../../../models/Form';

type Props = {
  referencesSelectionChanged: (mainId: string, id: string, include: boolean) => void;
  isLoading: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  loadMore: () => void;
  pagedData: ApiResponse<FormListItem[]> | null;
};

const DataExportChooseReferences: FC<Props> = (props) => {
  const { referencesSelectionChanged, isLoading, searchValue, setSearchValue, loadMore, pagedData } = props;

  const loadReferences = useCallback((id: string) => {
    return ClientFormService.getReferences(id, FormReferenceDirection.Source).then((res) => {
      return res.data.map((x) => {
        let title = x.subtitle || LanguageUtils.getTranslation('title', x.translations);
        if (x.type === FormType.Document) {
          title = `${StringUtils.makePrefixWithNumber(x.prefix, x.documentNumber ?? 0, x.templateModuleTranslations)}-${title}`;
        }
        return {
          id: x.id,
          title: title,
          version: `v${x.majorVersion}`,
          meta: x.isSystemTemplateForm
            ? LanguageUtils.getTranslation('name', x.templateModuleTranslations)
            : LanguageUtils.getTranslation('title', x.translations),
        };
      });
    });
  }, []);

  return (
    <DataExportMetaSelection
      subItemType="reference"
      loadSubItems={loadReferences}
      onItemStatusChange={referencesSelectionChanged}
      isLoading={isLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      loadMore={loadMore}
      pagedData={pagedData}
    />
  );
};

export default DataExportChooseReferences;
